import React, { useContext, useState } from "react"
import { QuizContext } from "../Helpers/Contexts"
import { Outlet, Link } from "react-router-dom"
import { storage } from "../firebase"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { v4 } from "uuid"
import "./StartScreen.css"

function StartScreen() {
  const { gameState, setgameState } = useContext(QuizContext)

  return (
    <div
      style={{
        height: "90vh",

        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 50,
      }}
    >
      <div>
        <h1
          style={{
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 700,
            textAlign: "center",
            fontSize: 35,
          }}
        >
          History in the eyes of AI
        </h1>
        <p
          style={{
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 500,
            textAlign: "center",
            fontSize: 18,
          }}
        >
          Immerse yourself inside a historical event created by AI
        </p>
      </div>
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          fontFamily: "Poppins",
          fontWeight: 500,
          textAlign: "center",
          fontSize: 18,
          borderRadius: 8,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          cursor: "pointer",
        }}
        onClick={() => setgameState("menu")}
      >
        Play Now
      </div>
      <div>
        <p
          style={{
            color: "black",
            fontFamily: "Poppins",
            fontWeight: 700,
            textAlign: "center",
            fontSize: 18,
          }}
        >
          Or
        </p>
      </div>

      <Link
        to="/Publish"
        style={{
          textDecoration: "none",
          padding: 10,
          backgroundColor: "black",
          borderRadius: 8,
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "white",
            fontFamily: "Poppins",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Help our game by adding your own questions
        </p>
      </Link>
    </div>
  )
}

export default StartScreen
