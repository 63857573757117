import API from "./index";

export const GetQuestions = (minYear, maxYear, limit) => {
  return new Promise(function (resolve, reject) {
    API.get(
      `/Questions?minYear=${minYear}&maxYear=${maxYear}&limit=${limit}&`,

      null,
      (data) => {
        resolve(data);
      },
      (err) => {
        console.log("Call IT SUPPORT @ 0706481528");
      }
    );
  });
};

export const postQuestion = (year, imageurl, title, location) => {
  return new Promise(function (resolve, reject) {
    API.post(
      `/post?year=${year}&imageurl=${imageurl}&title=${title}&location=${location}&`,

      null,
      (data) => {
        resolve(data);
      },
      (err) => {
        console.log("Call IT SUPPORT @ 0706481528");
      }
    );
  });
};

// export const postQuestion = (year, imageurl, title, location) => {
//   Axios.post("http://localhost:3001/post", {
//     year: year,
//     imageurl: imageurl,
//     title: title,
//     location: location,
//   }).then(() => {
//     console.log("success");
//   });
// };
