import React, { useContext } from "react";
import { QuizContext } from "../Helpers/Contexts";

import "./EndScreen.css";

function EndScreen() {
  const {
    score,
    setScore,
    setgameState,
    questions,
    savedAnswers,
    setSavedAnswers,
  } = useContext(QuizContext);

  const restartQuiz = () => {
    setScore(0);
    setgameState("menu");
    setSavedAnswers([]);
  };

  function renderResults() {
    return questions.map((question, index) => {
      return (
        <div className="event" style={{fontFamily:"Poppins"}}>
          <div className="eventdescription">
            <h2> Question {index + 1} </h2>
            <img id="image" src={question.prompt} />
            <h3>
              {" "}
              {question.description}, {question.year}, {question.location}
            </h3>
          </div>
          <div className="conclusion">
            <h3>
              {" "}
              You were off by {Math.abs(
                question.answer - savedAnswers[index]
              )}{" "}
              years{" "}
            </h3>
            <h4> Correct answer: {question.answer}</h4>
            <h4> Your answer: {savedAnswers[index]}</h4>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      <div className="overview" style={{fontFamily:"Poppins"}}>
        <h1>Quiz Finished</h1>
        <h3>
          {" "}
          {score} / {questions.length * 1000} points{" "}
        </h3>
      </div>
      <div className="conclusion_container">{renderResults()}</div>
      <div
        style={{
          display: "flex",
        }}
      >
        <button
          style={{
            width: 150,
            height: 50,
            borderRadius: 8,
            padding: 10,
            fontSize: 18,
            cursor: "pointer",
          }}
          onClick={restartQuiz}
        >
          Restart Quiz
        </button>
      </div>
    </div>
  );
}

export default EndScreen;
