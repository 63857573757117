require("dotenv").config();

// const BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3002"
//     : "https://hg-api.vercel.app";

// const BASE_URL = "https://hg-api.vercel.app";

const BASE_URL = "https://historyguessr.herokuapp.com";

// const BASE_URL = "https://localhost:3001";

const makeRequest = async (endpoint, method, body) => {
  const url = BASE_URL + endpoint;
  let headers = {
    "Content-Type": "application/json",
  };

  let reqBody = body ? JSON.stringify(body) : null;

  const response = await fetch(url, {
    method,
    headers,
    body: reqBody,
  });

  if (response.status === 401 && endpoint !== "v1/signin") {
    sessionStorage.removeItem("auth_token");
    window.location.reload();
  }

  return response;
};

const get = async (endpoint, body, onSuccess, onError) => {
  const res = await makeRequest(endpoint, "get", body);
  if (res.status !== 200) {
    if (onError) {
      onError(await res.json());
    }
  } else if (res.status == 200) {
    if (onSuccess) {
      onSuccess(await res.json());
    }
  }
};

const post = async (endpoint, body, onSuccess, onError) => {
  const res = await makeRequest(endpoint, "post", body);
  if (res.status !== 200) {
    if (onError) {
      onError(await res.json());
    }
  } else if (res.status == 200) {
    if (onSuccess) {
      onSuccess(await res.json());
    }
  }
};

const update = async (endpoint, body, onSuccess, onError) => {
  const res = await makeRequest(endpoint, "put", body);
  if (res.status !== 200) {
    if (onError) {
      onError(await res.json());
    }
  } else if (res.status == 200) {
    if (onSuccess) {
      onSuccess(await res.json());
    }
  }
};

const remove = async (endpoint, body, onSuccess, onError) => {
  const res = await makeRequest(endpoint, "delete", body);
  if (res.status !== 200) {
    if (onError) {
      onError(await res.json());
    }
  } else if (res.status == 200) {
    if (onSuccess) {
      onSuccess(await res.json());
    }
  }
};

export default {
  baseUrl: BASE_URL,
  get,
  post,
  update,
  remove,
};
