import React, { useContext } from "react"
import { QuizContext } from "../Helpers/Contexts"
import "./MainMenu2.css"
// import MultiRangeSlider from "../multiRangeSlider/multiRangeSlider";

function MainMenu2() {
  const {
    gameState,
    setgameState,
    startQuiz,
    setMaxYear,
    setMinYear,
    minYear,
    maxYear,
    limit,
    setLimit,
  } = useContext(QuizContext)

  function getDisplayYear(inputYear) {
    let year
    if (inputYear < 0) {
      year = Math.abs(inputYear).toString() + " BC"
      return year
    }
    if (inputYear > 0) {
      year = inputYear.toString() + " AD"
      return year
    } else {
      year = inputYear.toString()
      return year
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontFamily: "Poppins",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3 style={{ fontFamily: "Poppins", color: "white" }}>Choose Range</h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 200,
            fontFamily: "Poppins",
          }}
        >
          <div style={{ display: "flex" }}>
            <h3 style={{ color: "white" }}>FROM YEAR</h3>
          </div>
          <div
            style={{
              backgroundColor: "white",
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            <h4
              style={{
                textAlign: "center",
                color: "black",
              }}
            >
              {getDisplayYear(minYear)}
            </h4>
          </div>
        </div>
        <div style={{ position: "relative", width: "100%" }}>
          <input
            type="range"
            min="-2000"
            max="2023"
            className="thumb thumb--left"
            value={minYear}
            //onChange={(e) => setMinYear(e.target.value)}
            onChange={(event) => {
              const value = Math.min(Number(event.target.value), maxYear - 100)
              setMinYear(value)
            }}
          />
          <input
            type="range"
            min="-2000"
            max="2023"
            className="thumb thumb--right"
            value={maxYear}
            //onChange={(e) => setMaxYear(e.target.value)}
            onChange={(event) => {
              const value = Math.max(Number(event.target.value), minYear + 100)
              setMaxYear(value)
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 200,
            fontFamily: "Poppins",
          }}
        >
          <div style={{ display: "flex" }}>
            <h3 style={{ color: "white" }}>TO YEAR</h3>
          </div>
          <div
            style={{
              backgroundColor: "white",
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            <h4
              style={{
                textAlign: "center",
                color: "black",
              }}
            >
              {getDisplayYear(maxYear)}
            </h4>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <h3 style={{ color: "white", fontFamily: "Poppins" }}>
            Question Count
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: 40,
          }}
        >
          <div
            className={limit === 5 ? "restartbutton2" : "restartbutton1"}
            onClick={() => {
              setLimit(5)
            }}
          >
            5
          </div>
          <div
            className={limit === 10 ? "restartbutton2" : "restartbutton1"}
            onClick={() => {
              setLimit(10)
            }}
          >
            10
          </div>
          <div
            className={limit === 1000 ? "restartbutton2" : "restartbutton1"}
            onClick={() => {
              setLimit(1000)
            }}
          >
            Max
          </div>
        </div>
        <div
          style={{
            borderRadius: 8,
            backgroundColor: "white",
            fontSize: "16px",

            width: "320px",
            cursor: "pointer",
            fontWeight: 600,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
          onClick={() => {
            startQuiz()
          }}
        >
          <p style={{ textAlign: "center", color: "black" }}>START GAME</p>
        </div>
      </div>
    </div>
  )
}

export default MainMenu2
