import React from "react"
import { useState } from "react"
import { storage } from "../firebase"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { v4 } from "uuid"
import { postQuestion } from "../api/questions"

function Publish() {
  const [imageUpload, setImageUpload] = useState(null)
  const [imageurl, setImageurl] = useState("")
  const [title, setTitle] = useState(null)
  const [location, setLocation] = useState(null)
  const [year, setYear] = useState(null)

  // console.log(imageUpload);
  const uploadImage = () => {
    if (imageUpload == null) return

    const imageRef = ref(storage, `images/${v4()}`)
    uploadBytes(imageRef, imageUpload).then(() => {
      getDownloadURL(imageRef).then((url) => {
        setImageurl(url)
        console.log(url)
        console.log(imageurl)
        alert("Image selected")
      })
    })
  }

  const uploadQuestion = () => {
    postQuestion(year, imageurl, title, location).then(() => {
      console.log("question posted")
      console.log(imageurl)
      setImageUpload(null)
      setImageurl("")
      setTitle(null)
      setLocation(null)
      setYear(null)
    })
  }

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <header
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a href="/">
          <img id="image1" src={"./logosmall.png"} style={{ width: 300 }} />
        </a>
      </header>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 600,
        }}
      >
        <div
          style={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            padding: 4,
            borderRadius: 10,
            backgroundColor: "gray",
            width: "80%",
            padding: 30,
            backgroundColor: "white",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="title">History event title</label>
            <input
              placeholder="Fall of Constantinople"
              type="text"
              onChange={(event) => {
                setTitle(event.target.value)
              }}
              style={{
                padding: 4,
                marginTop: 2,
                borderRadius: 6,
                border: 0,
                backgroundColor: "#BBBBBB",
                height: 40,
                fontSize: "20px",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 30,
            }}
          >
            <label for="title">History event location</label>
            <input
              placeholder="Istanbul, Turkey"
              type="text"
              onChange={(event) => {
                setLocation(event.target.value)
              }}
              style={{
                padding: 4,
                marginTop: 2,
                borderRadius: 6,
                border: 0,
                backgroundColor: "#BBBBBB",
                height: 40,
                fontSize: "20px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 40,
              marginTop: 30,
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <label for="year">When did this event happen</label>
              <input
                placeholder="1453"
                type="number"
                onChange={(event) => {
                  setYear(event.target.value)
                }}
                style={{
                  padding: 4,
                  marginTop: 2,
                  borderRadius: 6,
                  border: 0,
                  backgroundColor: "#BBBBBB",
                  height: 40,
                  fontSize: "20px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Upload illustration</label>
                <input
                  type="file"
                  onChange={(event) => {
                    setImageUpload(event.target.files[0])
                  }}
                  style={{
                    padding: 4,
                    marginTop: 2,
                    borderRadius: 6,
                    height: 40,
                    fontSize: "20px",
                    width: 300,
                  }}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  style={{
                    borderRadius: 6,
                    padding: 6,
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                  onClick={uploadImage}
                >
                  Choose this picture
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 80,
            }}
          >
            <div
              style={{
                borderRadius: 8,
                padding: 2,
                marginTop: 10,
                paddingLeft: "20px",
                paddingRight: "20px",
                cursor: "pointer",
                fontWeight: 600,
                backgroundColor: "#EAA641",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={uploadQuestion}
            >
              <p style={{ color: "black", textAlign: "center" }}>SUBMIT</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Publish
