import React, { useState, useContext, useEffect } from "react"
import MainMenu2 from "./Components/MainMenu2"
import Quiz from "./Components/Quiz"
import EndScreen from "./Components/EndScreen"
import StartScreen from "./Components/StartScreen"
import "./App.css"
import { GetQuestions } from "./api/questions"
import { QuizContext } from "./Helpers/Contexts"

function App() {
  const [gameState, setgameState] = useState("start")
  const [score, setScore] = useState(0)
  const [maxYear, setMaxYear] = useState(2023)
  const [minYear, setMinYear] = useState(-2000)
  const [questions, setQuestions] = useState(null)
  const [loadedQuestions, setLoadedQuestions] = useState(false)
  const [savedAnswers, setSavedAnswers] = useState([])
  const [limit, setLimit] = useState(10)

  //  useEffect(() => {
  //   GetQuestions()
  //   .then(res => {
  //       setQuestions(res)
  //       setLoadedQuestions(true)
  //   })

  // }, [])

  async function startQuiz() {
    setgameState("quiz")
    await GetQuestions(minYear, maxYear, limit).then((res) => {
      setQuestions(res)
      setLoadedQuestions(true)
    })
  }

  return (
    <div style={{}}>
      <header
        style={{
          display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "center",
          zIndex: 99,
          top: 0,
        }}
      >
        <a href="/" sty>
          <img id="image1" src={"./logosmall.png"} style={{ width: 300 }} />
        </a>
      </header>
      <div>
        <QuizContext.Provider
          value={{
            gameState,
            setgameState,
            startQuiz,
            score,
            setScore,
            maxYear,
            setMaxYear,
            minYear,
            setMinYear,
            questions,
            setQuestions,
            loadedQuestions,
            savedAnswers,
            setSavedAnswers,
            limit,
            setLimit,
          }}
        >
          {gameState === "start" && <StartScreen />}
          {gameState === "menu" && <MainMenu2 />}
          {gameState === "quiz" && <Quiz />}
          {gameState === "end" && <EndScreen />}
        </QuizContext.Provider>
      </div>
    </div>
  )
}

export default App
