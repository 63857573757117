import React, { useState, useContext, Suspense, useRef } from "react"
import { QuizContext } from "../Helpers/Contexts"
import "./Quiz.css"
import {
  Canvas,
  extend,
  useFrame,
  useThree,
  useLoader,
} from "@react-three/fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import * as THREE from "three"

function Quiz() {
  const {
    score,
    setScore,
    setgameState,
    questions,
    loadedQuestions,
    savedAnswers,
    setSavedAnswers,
  } = useContext(QuizContext)

  extend({ OrbitControls })

  function Controls(props) {
    const { camera, gl } = useThree()
    const ref = useRef()
    useFrame(() => ref.current.update())
    return (
      <orbitControls
        ref={ref}
        target={[0, 0, 0]}
        {...props}
        args={[camera, gl.domElement]}
        maxDistance={500}
        minDistance={10}
      />
    )
  }

  function Dome({ imageUrl }) {
    const texture = useLoader(THREE.TextureLoader, imageUrl)
    return (
      <mesh>
        <sphereBufferGeometry attach="geometry" args={[500, 60, 40]} />
        <meshBasicMaterial
          attach="material"
          map={texture}
          side={THREE.BackSide}
        />
      </mesh>
    )
  }

  const [currQuestion, setcurrQuestion] = useState(0)
  const [optionChosen, onChange] = useState("0")

  if (!loadedQuestions) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Poppins",
          marginTop: 300,
        }}
      >
        <h3> Loading Questions please wait...</h3>
      </div>
    )
  }

  // if (questions === null) {
  //     setLoadingQuestions(true)
  // GetQuestions()
  // .then(res => {
  //     setQuestions(res)
  // })
  //     return "Loading"
  // } else if (loadingQuestions) {
  //     return "Loading"
  // }

  const diff = Math.abs(questions[currQuestion].answer - optionChosen)
  const newPoints = Math.max(1000 - diff, 0)

  // var diff = Math.abs((questions[currQuestion].answer) - optionChosen);
  // if (diff>1000) {
  //     diff = 0
  // }
  // var diff2 = Math.abs(1000-diff);
  // if (diff===0) {
  //     diff2 = 0
  // }

  const finishQuiz = () => {
    setScore(score + newPoints)
    // console.log("NEW SORE:", diff2, score+diff2)
    // setScore(score+diff2);
    setgameState("end")
    setSavedAnswers((arr) => [...arr, optionChosen])
    console.log(savedAnswers)
  }

  const nextQuestion = () => {
    setScore(score + newPoints)
    // console.log("NEW SORE:", diff, diff2, score+diff2)
    // setScore(score + diff2);
    setcurrQuestion(currQuestion + 1)
    setSavedAnswers((arr) => [...arr, optionChosen])
    console.log(savedAnswers)
  }

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/* <div className="event">
        <img id="game" src={questions[currQuestion].prompt} alt="loading" /> */}
      {/* <img id="game" src="https://historyguessr.s3.eu-north-1.amazonaws.com/QuestionImages/1945.jpg" alt="loading"/> */}
      {/* </div> */}

      <Canvas camera={{ position: [10, 0, 0.1] }}>
        <Controls
          enableZoom={true}
          enablePan={true}
          enableDamping
          dampingFactor={0.2}
          rotateSpeed={-0.5}
        />
        <Suspense fallback={null}>
          <Dome imageUrl={questions[currQuestion].prompt} />
        </Suspense>
      </Canvas>
      <div className="question">
        <div className="slidercontainer">
          <div className="results">
            <input
              type="range"
              min="-2000"
              max="2000"
              className="slider"
              value={optionChosen}
              onChange={({ target: { value: year } }) => {
                onChange(year)
              }}
            />
          </div>
          <div className="results">
            <input
              className="inputvalue"
              type="number"
              value={optionChosen}
              placeholder="-2000 : 2023"
              onChange={({ target: { value: radius } }) => {
                onChange(radius)
              }}
            />
          </div>
        </div>

        <div className="options">
          {currQuestion === questions.length - 1 ? (
            <div className="nextquestion" onClick={finishQuiz}>
              Finish Quiz
            </div>
          ) : (
            <div className="nextquestion" onClick={nextQuestion}>
              Next Question
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Quiz

//                <button onClick = {() => setoptionChosen("a")}>{Questions[currQuestion].optionA}</button>
